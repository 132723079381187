<template>
  <CForm @submit.prevent="checkForm" method="POST">
    <CCard>
      <CCardBody>
        <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>
        <CRow>
          <CCol col="9"></CCol>
          <CCol class="d-flex justify-content-end">
            <CButton
              color="secondary"
              class="btn-lg"
              @click="goBack"
              style="margin-right: 15px"
              ><CIcon name="cil-list" /> Back to the list</CButton
            >

            <CLoadingButton
              :disabled="loading"
              color="primary"
              type="submit"
              class="btn-lg"
              ><CIcon name="cil-save" /> Save</CLoadingButton
            >
          </CCol>
        </CRow>
        <br />
        <CRow>
          <CCol sm="4">
            <label style="width: 95%"
              >Name
              <a @click="toggleNames = !toggleNames"
                ><CIcon
                  name="cil-globe-alt"
                  style=""
                  class="text-primary"
                ></CIcon
              ></a>
            </label>
            <br />
            <br />
            <div
              class="input-group"
              style=""
              v-show="!toggleNames"
              v-if="category.names[0]"
            >
              <div class="input-group-prepend" style="height: 35px">
                <span
                  v-text="category.names[0].languageCode"
                  class="input-group-text"
                  style="width: 65px"
                />
              </div>
              <CCol sm="9">
                <CInput
                  label=""
                  rows="10"
                  @id="category.names[0].languageCode"
                  v-model="category.names[0].name"
                />
              </CCol>
            </div>
            <div
              v-for="(translation, k) in category.names"
              :key="k"
              v-show="toggleNames"
            >
              <div class="input-group" style="padding-bottom: 5px">
                <div class="input-group-prepend" style="height: 35px">
                  <span
                    v-text="translation.languageCode"
                    class="input-group-text"
                    style="width: 65px"
                  />
                </div>
                <CCol sm="9">
                  <CInput
                    label=""
                    rows="10"
                    @id="translation.languageCode"
                    v-model="category.names[k].name"
                  />
                </CCol>
              </div>
            </div>
          </CCol>

          <CCol sm="4">
            <label style="width: 95%"
              >Description
              <a @click="toggleDescriptions = !toggleDescriptions"
                ><CIcon
                  name="cil-globe-alt"
                  style=""
                  class="text-primary"
                ></CIcon
              ></a>
            </label>
            <br />
            <br />
            <div
              class="input-group"
              style=""
              v-show="!toggleDescriptions"
              v-if="category.descriptions[0]"
            >
              <div class="input-group-prepend" style="height: 35px">
                <span
                  v-text="category.descriptions[0].languageCode"
                  class="input-group-text"
                  style="width: 65px"
                />
              </div>
              <CCol sm="9">
                <CTextarea
                  label=""
                  rows="1"
                  @id="category.descriptions[0].languageCode"
                  v-model="category.descriptions[0].name"
                />
              </CCol>
            </div>
            <div
              v-for="(translation, k) in category.descriptions"
              :key="k"
              v-show="toggleDescriptions"
            >
              <div class="input-group" style="padding-bottom: 5px">
                <div class="input-group-prepend" style="height: 35px">
                  <span
                    v-text="translation.languageCode"
                    class="input-group-text"
                    style="width: 65px"
                  />
                </div>
                <CCol sm="9">
                  <CTextarea
                    label=""
                    rows="1"
                    @id="translation.languageCode"
                    v-model="category.descriptions[k].name"
                  />
                </CCol>
              </div>
            </div>
          </CCol>

          <CCol sm="4">
            <label style="width: 95%"
              >Url
              <a @click="toggleUrls = !toggleUrls"
                ><CIcon
                  name="cil-globe-alt"
                  style=""
                  class="text-primary"
                ></CIcon
              ></a>
            </label>
            <br />
            <br />
            <div
              class="input-group"
              style=""
              v-show="!toggleUrls"
              v-if="category.urls[0]"
            >
              <div class="input-group-prepend" style="height: 35px">
                <span
                  v-text="category.urls[0].languageCode"
                  class="input-group-text"
                  style="width: 65px"
                />
              </div>
              <CCol sm="9">
                <CInput
                  label=""
                  rows="10"
                  @id="category.urls[0].languageCode"
                  v-model="category.urls[0].name"
                />
              </CCol>
            </div>
            <div
              v-for="(translation, k) in category.urls"
              :key="k"
              v-show="toggleUrls"
            >
              <div class="input-group" style="padding-bottom: 5px">
                <div class="input-group-prepend" style="height: 35px">
                  <span
                    v-text="translation.languageCode"
                    class="input-group-text"
                    style="width: 65px"
                  />
                </div>
                <CCol sm="9">
                  <CInput
                    label=""
                    rows="10"
                    @id="translation.languageCode"
                    v-model="category.urls[k].name"
                  />
                </CCol>
              </div>
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol col="4">
            <div><label>Parent</label></div>
            <treeselect
              v-model="category.parentId"
              :defaultExpandLevel="Infinity"
              :isDefaultExpanded="true"
              :multiple="false"
              :options="categories"
            />
          </CCol>
          <CCol sm="4">
            <CInput
              label="Sort order"
              type="text"
              v-model="category.sortOrder"
            ></CInput
          ></CCol>
          <CCol sm="4">
            <CInput
              label="Category code"
              type="text"
              v-model="category.categoryCode"
            ></CInput
          ></CCol>
        </CRow>
        <CRow>
          <CCol v-if="paymentMethods.length > 0 && isDataLoaded" sm="12" md="8" lg="7" xl="4">
            <label>Payment methods</label>
            <CMultiSelect
              :multiple="true"
              :selection="true"
              v-model="category.partnerPaymentMethods"
              optionsEmptyPlaceholder="No payment methods available"
              selectionType="text"
              @update="appendValue($event)"
              :options="paymentMethods"
              :selected="category.partnerPaymentMethods"
            />
          </CCol>
        </CRow>
        <br />
        <div><label>Pictures</label></div>
        <images-uploader :thumbnails.sync="thumbnails"></images-uploader>
        <br />
        <br />
        <br />
        <br />

        <CModal
          v-model="category.id"
          title="Are you sure to delete this category?"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
        </CModal>
      </CCardBody>
    </CCard>
  </CForm>
</template>

<script>
import axios from "axios";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ImagesUploader from "@/components/ImagesUploader";

export default {
  name: "CreateOrUpdateCategory",
  components: {
    Treeselect,
    ImagesUploader,
  },
  props: {
    id: {
      type: String,
      default: "00000000-0000-0000-0000-000000000000",
    },
    parentId: {
      type: String,
      default: null,
    },
    thumbnails: [],
  },
  data: () => {
    return {
      operationName: null,
      emptyGuid: "00000000-0000-0000-0000-000000000000",

      category: {
        id: "00000000-0000-0000-0000-000000000000",
        names: [],
        descriptions: [],
        urls: [],
        parentId: "",
        sortOrder: null,
        categoryCode: null,
        picture: null,
        file: null,
      },
      paymentMethods: [],
      names: [],
      descriptions: [],
      urls: [],
      categories: [],
      languages: [],
      toggleNames: false,
      toggleDescriptions: false,
      toggleUrls: false,
      isDataLoaded: false,
      //Alert
      warningModal: false,
      message: null,
      alertType: "danger",
      loading: false,
      deleted: false,
    };
  },
  methods: {
    checkForm: function (e) {
      if (this.loading) return;
      this.loading = true;
      if (!this.category.sortOrder) {
        this.failed = true;
        this.message = "Invalid sort order.";
        this.loading = false;
        this.alertType = "danger";
        setTimeout(function () {
          this.message = null;
        }, 10000);
        return;
      }
      this.save();
    },
    goBack() {
      this.$emit("onOperationFinish", this.deleted);
    },
    loadCategoryHierarchy(categories, pId) {
      return categories
        .filter((c) => c.parentId === pId)
        .map((c) => {
          var subCategories = this.loadCategoryHierarchy(c.subCategories, c.id);
          return {
            id: c.id,
            label: c.names[0].name,
            children: subCategories.length > 0 ? subCategories : undefined,
          };
        });
    },
    appendValue(event) {
      this.category.partnerPaymentMethods = event;
    },
    handleFileUpload(event) {
      this.category.file = event.target.files[0];
    },

    save() {
      let self = this;
      let form = new FormData();
      form.append("id", self.category.id);
      form.append("namesJson", JSON.stringify(self.category.names));
      form.append(
        "descriptionsJson",
        JSON.stringify(self.category.descriptions)
      );
      if(self.category.partnerPaymentMethods){
        self.category.partnerPaymentMethods.forEach((guid, index) => {
        form.append(`partnerPaymentMethods[${index}]`, guid);
      });
      }

      form.append("urlsJson", JSON.stringify(self.category.urls));
      form.append("sortOrder", self.category.sortOrder);
      if (self.category.categoryCode)
        form.append("categoryCode", self.category.categoryCode);
      form.append("file", self.category.file);
      let newImages = self.thumbnails?.filter((thumbnail) => thumbnail.file);
      let thumbnailsUrls = self.getThumbnailsUrls();
      if (newImages) {
        let newImagesOrders = [];
        newImages.forEach((newImage) => {
          form.append("files", newImage.file);
          newImagesOrders.push({
            fileName: newImage.file.name,
            orderNumber: newImage.order,
          });
        });
        form.append("newImagesOrdersJson", JSON.stringify(newImagesOrders));
      }

      if (thumbnailsUrls) form.append("picture", thumbnailsUrls);
      if (
        self.category.parentId === null ||
        self.category.parentId === "null" ||
        self.category.parentId === "00000000-0000-0000-0000-000000000000"
      ) {
        self.category.parentId = "";
      }
      form.append("parentId", self.category.parentId);

      axios
        .post(`${this.$apiAdress}/v1/category`, form)
        .then((response) => {
          self.category.id = response.data;
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully updated category.";
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });

      self.loading = false;
    },

    getCategories() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/category/CategoryHierarchy")
        .then(function (response) {
          self.categories = [
            {
              id: "",
              label: "Main Category",
              children: self.loadCategoryHierarchy(response.data, null),
            },
          ];
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    getLanguages() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerLanguage`)
        .then(function (response) {
          response.data.forEach(function (language) {
            var translationForName = {
              languageId: language.id,
              languageCode: language.languageCode,
              name: "",
            };

            var translationForDescription = {
              languageId: language.id,
              languageCode: language.languageCode,
              name: "",
            };

            var translationForUrl = {
              languageId: language.id,
              languageCode: language.languageCode,
              name: "",
            };

            if (self.id == self.emptyGuid) {
              self.category.names.push(translationForName);
              self.category.descriptions.push(translationForDescription);
              self.category.urls.push(translationForUrl);
            }
          });
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getList() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerPaymentMethods`)
        .then(function (response) {
          self.items = response.data;
          self.paymentMethods.push(
            ...response.data.map((paymentMethod) => {
              return {
                value: paymentMethod.id,
                text:
                  paymentMethod.paymentMethodsType == 1
                    ? "Stripe Card"
                    : paymentMethod.paymentMethodsType == 2
                    ? "Stripe Klarna"
                    : "Pay Later  - " + paymentMethod.nameTranslations[0].name,
              };
            })
          );
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getCategory(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/category/${id}`)
        .then(function (response) {
          self.category = response.data;
          let thumbnailOrderIndex = 0;
          if (self.category?.picture != "null")
            self.thumbnails = self.category?.picture
              ?.split(",")
              .map((thumbnail) => {
                return {
                  url: thumbnail,
                  order: thumbnailOrderIndex++,
                  blob: null,
                  file: null,
                  show: false,
                };
              });
          if (!self.category.parentId) self.category.parentId = "";
          self.isDataLoaded = true;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
          self.isDataLoaded = true;
        });
    },

    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.category.id);
      }
    },
    getThumbnailsUrls() {
      if (this.thumbnails && this.thumbnails.length > 0) {
        return this.thumbnails
          .filter((thumbnail) => thumbnail.url)
          .map((thumbnail) => {
            return thumbnail.url;
          })
          .join();
      } else return null;
    },
    getThumbnailsFiles() {
      if (this.thumbnails && this.thumbnails.length > 0) {
        return this.thumbnails
          .filter((thumbnail) => thumbnail.file)
          .map((thumbnail) => {
            return thumbnail.file;
          });
      } else return null;
    },
    delete(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/category/${id}`)
        .then(function () {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully deleted category.";
          self.deleted = true;
        })
        .catch(function (error) {
          self.deleted = true;
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    let self = this;
    self.getCategories();
    self.getLanguages();
    this.getList();
    if (this.id != this.emptyGuid) {
      self.operationName = "Edit";
      self.getCategory(this.id);
    } else {
      self.isDataLoaded = true;
      self.operationName = "Create New";
    }

    if (this.parentId) {
      if (this.parentId == " ") this.parentId = "";
      this.category.parentId = this.parentId;
    }
  },
};
</script>
